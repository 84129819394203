<template>
  <div class="index">
       <keep-alive>
        <router-view v-if="$route.meta.keepAlive" />
      </keep-alive>
      <router-view v-if="!$route.meta.keepAlive"></router-view>
      <!-- <router-view></router-view> -->
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>