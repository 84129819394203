import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Vant from 'vant';
import 'vant/lib/index.css';
Vue.config.productionTip = false;
Vue.use(Vant);
// 

// 路由判断
router.beforeEach((to, form, next) => {
  let url = to.path;
  let token = localStorage.getItem('token')
  let routerAee = ['/login', '/', '/reg', '/reset', '/protocol', '/shops', '/shopmsg', '/download', '/invitation/get', '/invitation/show', '/download/okex','/download/ios']
  if (routerAee.indexOf(url) != -1) {
    next()
  } else {
    if (token) {
      next()
    } else {
      next('/login');
    }
  }
})
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
